<template>
  <table
    v-if="budSumList"
    style="float: left; margin-left: 20px; font-size: 18px; border-collapse: collapse"
  >
    <tr>
      <td style="padding: 0 48px 0 0; text-align: left"><h2>산출기초</h2></td>
      <td style="padding: 0; text-align: left">세입</td>
      <td style="padding: 0 10px; text-align: right; border-right: 1px solid #333">
        ₩ {{ budSumList.in_예산총액_총합 || 0 }}
      </td>

      <td style="padding: 0 0 0 10px; text-align: left">세출</td>
      <td style="padding: 0 10px; text-align: right; border-right: 1px solid #333">
        ₩ {{ budSumList.out_예산총액_총합 || 0 }}
      </td>

      <td style="padding: 0 0 0 10px; text-align: left">차액</td>
      <td
        :style="{
          padding: '0 10px',
          textAlign: 'right',
          borderRight: '1px solid #333',
          color:
            budSumList.in_total_budget_money - budSumList.out_total_budget_money === 0
              ? 'green'
              : 'red',
        }"
      >
        ₩
        {{
          addComma(
            budSumList.in_total_budget_money - budSumList.out_total_budget_money
          ) || 0
        }}
      </td>
      <td style="padding: 0 0 0 20px; text-align: left">인건비 지출 비율</td>
      <td>
        <span
          :style="{
            padding: '5px',
            textAlign: 'right',
            color:
              laborPercent > percent ? 'green' : laborPercent < percent ? 'red' : 'green',
          }"
          >{{ laborPercent || 0 }}%</span
        >
        <span v-if="laborPercent > percent" style="font-size: 16px"
          >&gt; {{ percent }}%</span
        >
        <span v-else-if="laborPercent < percent" style="font-size: 19px"
          >&lt; {{ percent }}%</span
        >
        <span v-else style="font-size: 16px">= {{ percent }}%</span>
      </td>
      <td><span style="margin-left: 10px;">기존 계산: {{realLaborPercent}}%</span></td>
    </tr>
  </table>

  <div style="padding: 0 16px 16px 16px; text-align: right">
    <v-btn @click="goBudgetList" class="jogunSelect">예산조회</v-btn>
    <BudgetBtns />
  </div>
  <div v-if="isLoading" class="loading-spinner"></div>

  <v-card class="table-container" v-else>
    <table class="budDetailListTable">
      <thead>
        <tr>
          <th>구분</th>
          <th>관</th>
          <th>항</th>
          <th>목</th>
          <th>예산액</th>
          <th>내용</th>
          <th>단가</th>
          <th>명(회)</th>
          <th>개월</th>
          <th>금액</th>
          <th>자금원천</th>
          <th colspan="3">기능</th>
        </tr>
      </thead>
      <tbody>
        <template v-if="mainGubun === '전체' || mainGubun === '세입'">
          <tr
            class="budListTr"
            v-for="(item, index) in budInListWithRowspan"
            :key="index"
          >
            <!-- 구분 -->
            <td
              v-if="item.rowspan.구분"
              :rowspan="item.rowspan.구분"
              class="tac defaultBgColor"
            >
              {{ item.구분 }}
            </td>
            <!-- 관 -->
            <td
              v-if="item.rowspan.관"
              :rowspan="item.rowspan.관"
              class="tac defaultBgColor"
            >
              {{ item.관 }}
              <p v-if="filteredLnameTotal(item)">
                총 {{ ac(filteredLnameTotal(item).total) }} 원
              </p>
            </td>
            <!-- 항 -->
            <td
              v-if="item.rowspan.항"
              :rowspan="item.rowspan.항"
              class="tac defaultBgColor"
            >
              {{ item.항 }}
              <p v-if="filteredMnameTotal(item)">
                총 {{ ac(filteredMnameTotal(item).total) }} 원
              </p>
            </td>
            <!-- 나머지 항목들은 그대로 출력 -->
            <td
              v-if="item.rowspan.목"
              :rowspan="item.rowspan.목"
              class="tac inMok pointer"
              @dblclick="setDbClickItem(item)"
            >
              {{ item.목 }}
            </td>

            <td
              v-if="item.rowspan.예산액"
              :rowspan="item.rowspan.예산액"
              class="tar bgColorGray"
            >
              {{ item.예산액 !== "0" ? item.예산액 : "" }}
            </td>

            <td class="tac bgColorGray">
              <div
                v-if="item.scode === '112' || item.scode === '611'"
                class="input-container"
              >

                <select
                  v-if="item.suega !== '직접입력'"
                  @change="suegaInputChange(item, 'suega'), handleChange(item)"
                  v-model="item.suega"
                  class="budDetailSelect tac"
                >
                  <option
                    v-for="(option, index) in getSuega"
                    :key="index"
                    :value="option.rank"
                  >
                    {{ option.rank }} <span v-if="item.suega === option.rank">▾</span>
                  </option>
                </select>

                <div style="height: 100%; margin: auto" v-if="item.suega !== '직접입력'">
                  X
                </div>
                <input
                  v-if="item.suega !== '직접입력'"
                  @input="suegaInputChange(item, 'days')"
                  @change="handleChange(item)"
                  class="budDetailInput tac"
                  style="width: 30%"
                  v-model="item.days"
                  type="number"
                />
              </div>
              <div v-if="item.suega === '직접입력'" style="height: 100%; display: flex; align-items: center">
                  <input class="tac budDetailInput" v-model="item.item" type="text" 
                    @change="handleChange(item)"
                  />
                  <span
                    v-if="
                      item.scode === '611' ||
                      item.scode === '112'
                    "
                    style="margin: 0 5px 0 10px"
                    class="pointer"
                    @click="directInputCancle(item)"
                    >X</span
                  >
                </div>
                <div v-if="item.suega !== '직접입력' && item.scode !== '611' &&
                      item.scode !== '112'" style="height: 100%; display: flex; align-items: center">
                  <input class="tac budDetailInput" v-model="item.item" type="text" 
                  @change="handleChange(item)"
                  />

                </div>
            </td>

            <td class="tar bgColorGray">
              <input
                class="budDetailInput"
                @focus="clearFormat(item, 'price')"
                @input="applyFormat(item, 'price')"
                @blur="commaBlur(item, 'price')"
                @change="handleChange(item)"
                v-model="item.price"
                type="text"
              />
            </td>
            <td class="tac bgColorGray">
              <input
                class="tac budDetailInput"
                @focus="clearFormat(item, 'person')"
                @input="applyFormat(item, 'person')"
                @blur="commaBlur(item, 'person')"
                @change="handleChange(item)"
                v-model="item.person"
                type="text"
              />
            </td>
            <td class="tac bgColorGray">
              <input
                class="tac budDetailInput"
                @focus="clearFormat(item, 'month')"
                @input="applyFormat(item, 'month')"
                @blur="commaBlur(item, 'month')"
                @change="handleChange(item)"
                v-model="item.month"
                type="text"
              />
            </td>
            <td class="tar bgColorGray">{{ item.amount !== "0" ? item.amount : "" }}</td>
            <td class="tac bgColorGray">
              <select v-model="item.funding_src" class="budDetailSelect"
              @change="handleChange(item)"
>
                <option
                  v-for="option in fundingValue"
                  :key="option.value"
                  :value="option.value"
                >
                  {{ option.label }}
                </option>
              </select>
            </td>
            <td
              v-if="!isLoadingMulti"
              class="tac budgetHoverBtn pointer"
              :class="{ clicked: clickEffectIndex === index }"
              @click="handleClick(item, index)"
            >
            <button class="budgetSaveBtn">
              <template
                v-if="
                  item.idx &&
                  (item.item || (item.suega && item.days && item.suega !== '직접입력')) &&
                  item.price &&
                  item.person &&
                  item.month &&
                  item.funding_src &&
                  item.changeStatus
                "
              >
                <v-icon style="color: rgb(0, 137, 0)">{{ mdiContentSaveCheck }}</v-icon>
              </template>
              <template
                v-else-if="
                  (!item.idx &&
                  (item.item || (item.suega && item.days && item.suega !== '직접입력')) &&
                  item.price &&
                  item.person &&
                  item.month &&
                  item.funding_src) || 
                  (item.idx &&(item.item || (item.suega && item.days && item.suega !== '직접입력')) &&item.price &&
                  item.person &&
                  item.month &&
                  item.funding_src&& !item.changeStatus)
                "
              >
                <v-icon style="color: #db4e2e">{{ mdiContentSave }}</v-icon>
              </template>
              <template v-else>
                <v-icon style="color: rgba(0, 0, 0, 0.314)">{{
                  mdiContentSaveOff
                }}</v-icon>
              </template>
            </button>
            </td>
            <td class="tac budgetHoverBtn pointer" v-if="isLoadingMulti">
              <div class="loading-spinner-small"></div>
            </td>

            <td @click="delRow(item, index)" class="tac budgetHoverBtn pointer">❌</td>
            <td
              v-if="item.rowspan.예산액"
              :rowspan="item.rowspan.예산액"
              class="tac pointer"
              @click="addRowIn(index)"
            >
              ➕
            </td>
          </tr>
        </template>

        <template v-if="mainGubun === '전체' || mainGubun === '세출'">
          <tr
            class="budListTr"
            v-for="(item, index) in budOutListWithRowspan"
            :key="index"
          >
            <!-- 구분 -->
            <td
              v-if="item.rowspan.구분"
              :rowspan="item.rowspan.구분"
              class="tac defaultBgColor"
            >
              {{ item.구분 }}
            </td>
            <!-- 관 -->
            <td
              v-if="item.rowspan.관"
              :rowspan="item.rowspan.관"
              class="tac defaultBgColor"
            >
            {{ item.관 }}
              <p v-if="filteredLnameTotal(item)">
                총 {{ ac(filteredLnameTotal(item).total) }} 원
              </p>
            </td>
            <!-- 항 -->
            <td
              v-if="item.rowspan.항"
              :rowspan="item.rowspan.항"
              class="tac defaultBgColor"
            >
            {{ item.항 }}
              <p v-if="filteredMnameTotal(item)">
                총 {{ ac(filteredMnameTotal(item).total) }} 원
              </p>
            </td>
            <td
              v-if="item.rowspan.목"
              :rowspan="item.rowspan.목"
              class="tac outMok pointer"
              @dblclick="setDbClickItem(item)"
            >
              {{ item.목 }}
            </td>
            <td
              v-if="item.rowspan.예산액"
              :rowspan="item.rowspan.예산액"
              class="tar bgColorGray"
            >
              {{ item.예산액 !== "0" ? item.예산액 : "" }}
            </td>
            <td class="tac bgColorGray">
              <template
                v-if="
                  (item.scode === '1111' || item.scode === '1112') &&
                  jobItemValue1.map((option) => option.value).includes(item.item)
                "
              >
                <div style="height: 100%; display: flex; align-items: center">
                  <select v-model="item.item" class="budDetailSelect"
                  @change="handleChange(item)"
>
                    <option
                      v-for="option in jobItemValue1"
                      :key="option.value"
                      :value="option.value"
                    >
                      {{ option.label }}
                    </option>
                    <option value="">직접입력</option>
                  </select>
                  <v-tooltip class="fontNotoSans400" text="대표자 체크">
                    <template v-slot:activator="{ props }">
                      <input
                        v-bind="props"
                        type="checkbox"
                        v-model="item.resrc_class"
                        :true-value="'Y'"
                        :false-value="''"
                        style="margin: 0 2px 0 8px"
                        @change="handleChange(item)"

                      />
                    </template>
                  </v-tooltip>
                </div>
              </template>

              <template
                v-else-if="
                  (item.scode === '1141' || item.scode === '1142') &&
                  jobItemValue2.map((option) => option.value).includes(item.item)
                "
              >
                <div style="height: 100%; display: flex; align-items: center">
                  <select v-model="item.item" class="budDetailSelect"
                  @change="handleChange(item)"
>
                    <option
                      v-for="option in jobItemValue2"
                      :key="option.value"
                      :value="option.value"
                    >
                      {{ option.label }}
                    </option>
                    <option value="">직접입력</option>
                  </select>
                  <!-- 체크박스 추가 -->
                  <v-tooltip class="fontNotoSans400" text="대표자 체크">
                    <template v-slot:activator="{ props }">
                      <input
                        v-bind="props"
                        type="checkbox"
                        v-model="item.resrc_class"
                        :true-value="'Y'"
                        :false-value="''"
                        style="margin: 0 2px 0 8px"
                        @change="handleChange(item)"

                      />
                    </template>
                  </v-tooltip>
                </div>
              </template>

              <template
                v-else-if="
                  (item.scode === '1151' || item.scode === '1152') &&
                  jobItemValue3.map((option) => option.value).includes(item.item)
                "
              >
                <div style="height: 100%; display: flex; align-items: center">
                  <select v-model="item.item" class="budDetailSelect"
                  @change="handleChange(item)"
>
                    <option
                      v-for="option in jobItemValue3"
                      :key="option.value"
                      :value="option.value"
                    >
                      {{ option.label }}
                    </option>
                    <option value="">직접입력</option>
                  </select>
                  <!-- 체크박스 추가 -->
                  <v-tooltip class="fontNotoSans400" text="대표자 체크">
                    <template v-slot:activator="{ props }">
                      <input
                        v-bind="props"
                        type="checkbox"
                        v-model="item.resrc_class"
                        :true-value="'Y'"
                        :false-value="''"
                        style="margin: 0 2px 0 8px"
                        @change="handleChange(item)"

                      />
                    </template>
                  </v-tooltip>
                </div>
              </template>

              <div v-else style="height: 100%; display: flex; align-items: center">
                <input class="tac budDetailInput" v-model="item.item" type="text"
                @change="handleChange(item)"
/>
                <span
                  v-if="
                    item.scode === '1111' ||
                    item.scode === '1112' ||
                    item.scode === '1141' ||
                    item.scode === '1142' ||
                    item.scode === '1151' ||
                    item.scode === '1152'
                  "
                  style="margin: 0 5px 0 10px"
                  class="pointer"
                  @click="directInputCancle(item)"
                  >X</span
                >
              </div>
            </td>
            <td class="tar bgColorGray">
              <input
                class="budDetailInput"
                @focus="clearFormat(item, 'price')"
                @input="applyFormat(item, 'price')"
                @blur="commaBlur(item, 'price')"
                @change="handleChange(item)"

                v-model="item.price"
                type="text"
              />
            </td>
            <td class="tac bgColorGray">
              <input
                class="tac budDetailInput"
                @focus="clearFormat(item, 'person')"
                @input="applyFormat(item, 'person')"
                @blur="commaBlur(item, 'person')"
                @change="handleChange(item)"

                v-model="item.person"
                type="text"
              />
            </td>
            <td class="tac bgColorGray">
              <input
                class="tac budDetailInput"
                @focus="clearFormat(item, 'month')"
                @input="applyFormat(item, 'month')"
                @blur="commaBlur(item, 'month')"
                @change="handleChange(item)"

                v-model="item.month"
                type="text"
              />
            </td>
            <td class="tar bgColorGray">{{ item.amount !== "0" ? item.amount : "" }}</td>
            <td class="tac bgColorGray">
              <select v-model="item.funding_src" class="budDetailSelect"
              @change="handleChange(item)"
>
                <option
                  v-for="option in fundingValue"
                  :key="option.value"
                  :value="option.value"
                >
                  {{ option.label }}
                </option>
              </select>
            </td>
            <template v-if="item.scode !== '1111' && item.scode !== '1112'">
              <td
                v-if="!isLoadingMulti"
                class="tac budgetHoverBtn pointer"
                :class="{ clicked: clickEffectIndex === index }"
                @click="handleClick(item, index)"
              >
              <button class="budgetSaveBtn">
                <template
                  v-if="
                    (item.idx &&
                    item.item &&
                    item.item != 'password@@@#123' &&
                    item.item !== '선택(퇴직금)' &&
                    item.item !== '선택(사회보험부담금)' &&
                    item.price &&
                    item.person &&
                    item.month &&
                    item.funding_src &&
                    item.changeStatus)
                  "
                >
                  <v-icon style="color: rgb(0, 137, 0)">{{ mdiContentSaveCheck }}</v-icon>
                </template>
                <template v-else-if="
                    item.idx &&
                    item.scode === '1142' &&
                    item.item &&
                    item.item !== '선택(퇴직금)' &&
                    item.person &&
                    item.month &&
                    item.funding_src &&
                    item.changeStatus
                    ">
                    <v-icon style="color: rgb(0, 137, 0)">{{ mdiContentSaveCheck }}</v-icon>
                </template>
                <template v-else-if="
                    (item.idx &&
                    item.scode === '1142' &&
                    item.item &&
                    item.price &&
                    item.item !== '선택(퇴직금)' &&
                    item.person &&
                    item.month &&
                    item.funding_src &&
                    !item.changeStatus) || 
                    item.idx &&
                    item.scode === '1142' &&
                    item.item &&
                    item.item !== '선택(퇴직금)' &&
                    item.person &&
                    item.month &&
                    item.funding_src &&
                    !item.changeStatus
                    ">
                    <v-icon style="color: #db4e2e">{{ mdiContentSave }}</v-icon>
                </template>
                <template
                  v-else-if="
                   (!item.idx &&
                    item.item &&
                    item.item != 'password@@@#123' &&
                    item.item !== '선택(퇴직금)' &&
                    item.item !== '선택(사회보험부담금)' &&
                    item.price &&
                    item.person &&
                    item.month &&
                    item.funding_src) ||
                (item.idx &&
                    item.item &&
                    item.item != 'password@@@#123' &&
                    item.item !== '선택(퇴직금)' &&
                    item.item !== '선택(사회보험부담금)' &&
                    item.price &&
                    item.person &&
                    item.month &&
                    item.funding_src &&
                    !item.changeStatus
                    )
                  "
                >
                  <v-icon style="color: #db4e2e">{{ mdiContentSave }}</v-icon>
                </template>
                <template v-else>
                  <v-icon style="color: rgba(0, 0, 0, 0.314)">{{
                    mdiContentSaveOff
                  }}</v-icon>
                </template>
                </button>
              </td>
              <td class="tac budgetHoverBtn pointer" v-if="isLoadingMulti">
                <div class="loading-spinner-small"></div>
              </td>
            </template>
            <template v-else>
              <td
                class="tac budgetHoverBtn pointer"
                :class="{ clicked: clickEffectIndex === index }"
                @click="handleMultiClick(item.scode, index)"
                v-if="item.rowspan?.예산액 && !isLoadingMulti"
                :rowspan="item.rowspan.예산액"
              >
              <button class="budgetSaveBtn">

                <template v-if="iconConditions[index]?.showCheckedIcon">
                  <!-- 저장 완료 -->
                  <v-icon style="color: rgb(0, 137, 0)">{{ mdiContentSaveCheck }}</v-icon>
                </template>
                <template v-else-if="iconConditions[index]?.showSaveIcon">
                  <!-- 저장 가능 -->
                  <v-icon style="color: #db4e2e">{{ mdiContentSave }}</v-icon>
                </template>
                <template v-else>
                  <!-- 저장 불가능 -->
                  <v-icon style="color: rgba(0, 0, 0, 0.314)">{{
                    mdiContentSaveOff
                  }}</v-icon>
                </template>
                </button>
              </td>
              <td
                class="tac budgetHoverBtn pointer"
                v-if="item.rowspan?.예산액 && isLoadingMulti"
                :rowspan="item.rowspan.예산액"
              >
                <div class="loading-spinner-small"></div>
              </td>
            </template>

            <!--  디스켓 하나로 합치고, 다중인서트, 업데이트 기능 필요함. 
                    삭제기능은 따로두는게 나을듯. 
                    생성,수정,삭제 시 퇴직금, 사회보험부담금에 영향이 미쳐야 함.-->
            <td @click="delRow(item, index)" class="tac budgetHoverBtn pointer">❌</td>
            <td
              v-if="item.rowspan.예산액"
              :rowspan="item.rowspan.예산액"
              class="tac pointer"
              @click="addRowOut(index)"
            >
              ➕
            </td>
          </tr>
        </template>
      </tbody>
    </table>
  </v-card>
  <div class="text-center">
    <v-snackbar
      v-model="snackbar"
      :timeout="2000"
      color="deep-purple-accent-1"
      rounded="pill"
      elevation="24"
      class="budgetSnackBar"
    >
      {{ text }}
    </v-snackbar>
  </div>
</template>
<script setup lang="ts">
import BudgetBtns from "@/components/budget/BudgetBtns.vue";

import store from "@/store";
import { computed, onMounted, ref, watch } from "vue";
import axios from "axios";
import router from "@/router";
import { mdiContentSave, mdiContentSaveOff, mdiContentSaveCheck } from "@mdi/js";
const isLoading = ref(false);
const isLoadingMulti = ref(false);

const snackbar = ref(false);
const text = ref("저장이 완료되었습니다.");

const mainComp = ref(null) as any;
const mainCompBizGubun = ref(null) as any;
const mainYear = ref(null) as any;
const budNum = ref(null) as any;
const mainGubun = ref(null) as any;
const budSumList = ref();

const budList = ref([] as any[]);
const budOutList = ref([] as any[]);
const budInList = ref([] as any[]);
// 더블 클릭 이벤트 핸들러
function setDbClickItem(item: any) {
  if (
    item.scode !== "112" &&
    item.scode !== "611" &&
    item.scode !== "1111" &&
    item.scode !== "1112" &&
    item.scode !== "1141" &&
    item.scode !== "1142" &&
    item.scode !== "1151" &&
    item.scode !== "1152"
  ) {
    item.item = item.목; // item 객체의 item 속성을 item.목으로 설정
  }
}
const mnameTotal = ref([] as any);
const lnameTotal = ref([] as any);

const jobTitles = [
  "password@@@#123",
  "간호사",
  "간호조무사",
  "관리인",
  "기타",
  "물리치료사",
  "사무국장",
  "사무원",
  "사회복지사",
  "시설장",
  "영양사",
  "요양보호사",
  "운전원",
  "위생원",
  "작업치료사",
  "조리사",
  "조리원",
];

function handleChange(item: any) {
  const matchedItem = budList.value.find(
      (budItem: any) => item.idx && budItem.idx === item.idx
    );
  if(
    item.idx &&
    matchedItem.content === item.content &&
    matchedItem.days === item.days &&
    matchedItem.funding_src === item.funding_src &&
    matchedItem.item === item.item &&
    (nc_num(matchedItem.month) === item.month || matchedItem.month === item.month) &&
    (nc_num(matchedItem.person) === item.person || matchedItem.person === item.person) &&
    (nc_num(matchedItem.price) === item.price || matchedItem.price === item.price) &&
    matchedItem.resrc_class === item.resrc_class &&
    matchedItem.suega === item.suega 
  ){
    item.changeStatus = true;
  }else{
    item.changeStatus = false;
  }
}


const goBudgetList = () => {
  router.push("/MngBudgetList");
};
const createJobItems = (suffix: string) =>
  jobTitles.map((title) => ({
    value: title === "password@@@#123" ? title : `${title} ${suffix}`,
    label: title === "password@@@#123" ? `선택(${suffix})` : `${title} ${suffix}`,
  }));

const jobItemValue1 = createJobItems("급여");
const jobItemValue2 = createJobItems("퇴직금");
const jobItemValue3 = createJobItems("사회보험부담금");

const fundingValue = [
  { value: "보조금", label: "보조금" },
  { value: "수익사업", label: "수익사업" },
  { value: "후원금", label: "후원금" },
  { value: "자부담", label: "자부담" },
];

const budInListWithRowspan = ref([] as any[]);
const budOutListWithRowspan = ref([] as any[]);

const directInputCancle = (item: any) => {
  if (item.구분 === "세출") {
    item.item = "password@@@#123";
    item.resrc_class = "";
  } else {
    item.suega = "";
    item.days = 30;
  }
};
// 행 추가 메서드
const addRowIn = (index: number) => {
  if (isLoadingMulti.value) {
    return;
  }
  // 새로운 빈 행 추가 예시
  const newRow = {
    구분: budInListWithRowspan.value[index].구분,
    관: budInListWithRowspan.value[index].관,
    항: budInListWithRowspan.value[index].항,
    목: budInListWithRowspan.value[index].목,
    예산액: budInListWithRowspan.value[index].예산액,
    보조금: 0,
    자부담: 0,
    후원금: 0,
    수익사업: 0,
    예산총액: 0,
    rowspan: { 구분: 1, 관: 1, 항: 1, 목: 1, 예산액: 1 },
    scode: budInListWithRowspan.value[index].scode,
    funding_src: budInListWithRowspan.value[index].funding_src,
  };

  const scode = newRow.scode;

  // 같은 scode를 가진 마지막 항목의 인덱스를 찾기
  const lastIndex = budInListWithRowspan.value
    .map((item, idx) => (item.scode === scode ? idx : -1))
    .filter((idx) => idx !== -1)
    .pop(); // 마지막 인덱스 가져오기

  const insertIndex = lastIndex !== undefined ? lastIndex + 1 : index + 1;

  // 새 행 추가
  budInListWithRowspan.value.splice(insertIndex, 0, newRow);

  // 나머지 작업 유지
  budInListWithRowspan.value.forEach((item) => {
    if (isTargetScode(item.scode) && (!item.person || item.person === 0)) {
      item.person = w4cInfo.value?.personnel;
      item.month = 12;
    }
    if (item.scode === "112" || item.scode === "611") {
      if (!item.item) {
        if (mainCompBizGubun.value === "공동생활가정") {
          // item.suega = "1등급수가";
          item.days = 30;
        }
        if (mainCompBizGubun.value === "방문목욕") {
          // item.suega = "차량이용(차량 내)수가";
          item.days = 5;
        }
        if (mainCompBizGubun.value === "방문요양") {
          // item.suega = "180분수가";
          item.days = 26;
        }
        if (mainCompBizGubun.value === "요양시설") {
          // item.suega = "2등급수가";
          item.days = 30;
        }
        if (mainCompBizGubun.value === "주야간보호") {
          // item.suega = "(8시간)3등급수가";
          item.days = 25;
        }
        item.suega = '직접입력'
        let matchingSuega = getSuega.value.find(
          (suega: { rank: string }) => suega.rank === item.suega
        );
        if (item.scode === "112") {
          item.price = ac(matchingSuega?.본인부담 * item.days);
        } else if (item.scode === "611") {
          item.price = ac((matchingSuega?.수가 - matchingSuega?.본인부담) * item.days);
        }
      } else if (!item.suega || !item.days) {
        item.suega = "직접입력";
        item.days = 1;
      }
    }
    if (!item.idx) {
      if (isTargetScode(item.scode)) {
        item.month = 12;
      } else {
        item.month = "";
      }
      item.amount =
        ac(nc(item.price || 0) * nc(item.person || 0) * nc(item.month || 0)) || "";
      const totalAmount = budInListWithRowspan.value
        .filter((bud) => bud.scode === item.scode)
        .reduce((sum, bud) => sum + nc(bud.amount || 0), 0);

      budInListWithRowspan.value
        .filter((bud) => bud.scode === item.scode)
        .forEach((bud) => {
          bud.예산액 = ac(totalAmount);
        });
    }
  });

  budInListWithRowspan.value = calculateRowspan(budInListWithRowspan.value);
};

// 행 추가 메서드
const addRowOut = (index: number) => {
  if (isLoadingMulti.value) {
    return;
  }
  // 새로운 빈 행 추가 예시
  const newRow = {
    구분: budOutListWithRowspan.value[index].구분,
    관: budOutListWithRowspan.value[index].관,
    항: budOutListWithRowspan.value[index].항,
    목: budOutListWithRowspan.value[index].목,
    예산액: budOutListWithRowspan.value[index].예산액,
    보조금: 0,
    자부담: 0,
    후원금: 0,
    수익사업: 0,
    예산총액: 0,
    rowspan: { 구분: 1, 관: 1, 항: 1, 목: 1, 예산액: 1 },
    scode: budOutListWithRowspan.value[index].scode,
    item: ["1111", "1112", "1141", "1142", "1151", "1152"].includes(
      budOutListWithRowspan.value[index].scode
    )
      ? "password@@@#123"
      : undefined, // 조건에 맞지 않으면 item을 설정하지 않음
  };
  // 리스트에 새 행 추가

  const scode = newRow.scode;

  // 같은 scode를 가진 마지막 항목의 인덱스를 찾기
  const lastIndex = budOutListWithRowspan.value
    .map((item, idx) => (item.scode === scode ? idx : -1))
    .filter((idx) => idx !== -1)
    .pop(); // 마지막 인덱스 가져오기

  const insertIndex = lastIndex !== undefined ? lastIndex + 1 : index + 1;

  // 새 행 추가
  budOutListWithRowspan.value.splice(insertIndex, 0, newRow);

  budOutListWithRowspan.value = calculateRowspan(budOutListWithRowspan.value);
};

const delRow = async (item: any, index: number) => {
  if (isLoadingMulti.value) return;

  // '관', '항', '목', '구분'만 남아있는지 확인
  const essentialKeys = ["관", "항", "목", "구분", "scode", "rowspan"];
  const isOnlyEssentialData = Object.keys(item).every(
    (key) => essentialKeys.includes(key) || item[key] === null
  );

  // '관', '항', '목', '구분'만 남아있으면 함수 종료
  if (isOnlyEssentialData) return;
  if(!item.idx){
    let budRowspan = ref([] as any);
  if (item.구분 === "세입") {
    budRowspan.value = budInListWithRowspan.value;
  } else {
    budRowspan.value = budOutListWithRowspan.value;
  }
  const remainingItems = budRowspan.value.filter((row: any) => row["목"] === item["목"]);
  if (remainingItems.length === 1) {
    // '목' 컬럼이 하나만 남아있다면, 관, 항, 목, 구분만 남기고 나머지 데이터는 모두 삭제
    Object.keys(item).forEach((key) => {
      if (!essentialKeys.includes(key)) {
        item[key] = null;
      }
    });
  }else{
    budRowspan.value.splice(index, 1);
  }
  budInListWithRowspan.value = calculateRowspan(budInListWithRowspan.value);
  budOutListWithRowspan.value = calculateRowspan(budOutListWithRowspan.value);
  
  }else if (item.idx) {
    const isConfirmed = window.confirm(`정말 삭제하시겠습니까?`);
  if (!isConfirmed) return;
    await deleteBudgetDetail(item);
  }
};

const calculateRowspan = (list: any[]) => {
  const updatedList = list.map((item) => ({
    ...item,
    rowspan: { 구분: 1, 관: 1, 항: 1, 목: 1, 예산액: 1 },
  }));
  let 구분Rowspan = 1,
    관Rowspan = 1,
    항Rowspan = 1,
    목Rowspan = 1,
    예산액Rowspan = 1;

  for (let i = 0; i < updatedList.length; i++) {
    const current = updatedList[i];
    const prev = updatedList[i - 1];

    if (prev && prev.구분 === current.구분) {
      구분Rowspan++;
      current.rowspan.구분 = 0;
    } else {
      if (i > 0) updatedList[i - 구분Rowspan].rowspan.구분 = 구분Rowspan;
      구분Rowspan = 1;
    }

    if (prev && prev.관 === current.관 && prev.구분 === current.구분) {
      관Rowspan++;
      current.rowspan.관 = 0;
    } else {
      if (i > 0) updatedList[i - 관Rowspan].rowspan.관 = 관Rowspan;
      관Rowspan = 1;
    }

    if (
      prev &&
      prev.항 === current.항 &&
      prev.관 === current.관 &&
      prev.구분 === current.구분
    ) {
      항Rowspan++;
      current.rowspan.항 = 0;
    } else {
      if (i > 0) updatedList[i - 항Rowspan].rowspan.항 = 항Rowspan;
      항Rowspan = 1;
    }

    if (
      prev &&
      prev.목 === current.목 &&
      prev.항 === current.항 &&
      prev.관 === current.관 &&
      prev.구분 === current.구분
    ) {
      목Rowspan++;
      current.rowspan.목 = 0;
    } else {
      if (i > 0) updatedList[i - 목Rowspan].rowspan.목 = 목Rowspan;
      목Rowspan = 1;
    }

    if (
      prev &&
      prev.예산액 === current.예산액 &&
      prev.목 === current.목 &&
      prev.항 === current.항 &&
      prev.관 === current.관 &&
      prev.구분 === current.구분
    ) {
      예산액Rowspan++;
      current.rowspan.예산액 = 0;
    } else {
      if (i > 0) updatedList[i - 예산액Rowspan].rowspan.예산액 = 예산액Rowspan;
      예산액Rowspan = 1;
    }
  }
  // 마지막 그룹에 대해 rowspan 설정
  if (updatedList.length > 0) {
    updatedList[updatedList.length - 구분Rowspan].rowspan.구분 = 구분Rowspan;
    updatedList[updatedList.length - 관Rowspan].rowspan.관 = 관Rowspan;
    updatedList[updatedList.length - 항Rowspan].rowspan.항 = 항Rowspan;
    updatedList[updatedList.length - 목Rowspan].rowspan.목 = 목Rowspan;
    updatedList[updatedList.length - 예산액Rowspan].rowspan.예산액 = 예산액Rowspan;
  }
  return updatedList;
};
const laborPercent = ref();
const w4cInfo = ref();
const getSuega = ref();
const percent = ref();
const laborOut = ref();
const laborIn = ref();
const pr = ref();

const getBudgetDetailList = async () => {
  try {
    const response = await axios.get(
      `/api/getBudgetDetailList/${mainComp.value}/${mainCompBizGubun.value}/${mainYear.value}/${budNum.value}`
    );
    const responseData = response.data;
     w4cInfo.value = responseData?.w4cInfo;
    if (
      !w4cInfo.value?.personnel ||
      !w4cInfo.value.w4c_pay_gubun ||
      !w4cInfo.value.w4c사업명 ||
      !w4cInfo.value.w4c사업코드
    ) {
      alert("w4c기관정보를 입력해주세요.");
    }
    budList.value = responseData?.result || [];
    budSumList.value = responseData?.sum;
    percent.value = responseData?.percent?.percentage || null;
    getSuega.value = responseData?.getSuega;
    pr.value = responseData?.pr;
    laborOut.value = responseData.laborPercent.labor_out;
    laborIn.value = responseData.laborPercent.labor_in;
    //직접입력 추가
    getSuega.value.push({ 수가: 0, 본인부담: 0, rank: "직접입력" });
    laborPercent.value =
      parseFloat(
        (
          (laborOut.value / laborIn.value) *
          100
        ).toFixed(1)
      ) || 0;
    if (laborPercent.value === Infinity) {
      laborPercent.value = ""; // 값이 없으면 0으로 설정
    }
    realLaborPercent.value = laborPercent.value;
    budOutList.value = budList.value.filter(
      (item: { 구분: string }) => item.구분 === "세출"
    );
    budInList.value = budList.value.filter(
      (item: { 구분: string }) => item.구분 === "세입"
    );
    // budInList의 rowspan 계산
    budInListWithRowspan.value = calculateRowspan(budInList.value);
    budOutListWithRowspan.value = calculateRowspan(budOutList.value);
    //정원 디폴트로 집어넣기위함.
    budInListWithRowspan.value.forEach((item) => {
      if (isTargetScode(item.scode) && (!item.person || item.person === 0)) {
        item.person = w4cInfo.value?.personnel;
        item.month = 12;
      }

      if (item.scode === "112" || item.scode === "611") {
        if (!item.item) {
          // 값이 없는 경우.
          if (mainCompBizGubun.value === "공동생활가정") {
            item.suega = "1등급수가";
            item.days = 30;
          }
          if (mainCompBizGubun.value === "방문목욕") {
            item.suega = "차량이용(차량 내)수가";
            item.days = 5;
          }
          if (mainCompBizGubun.value === "방문요양") {
            item.suega = "180분수가";
            item.days = 26;
          }
          if (mainCompBizGubun.value === "요양시설") {
            item.suega = "2등급수가";
            item.days = 30;
          }
          if (mainCompBizGubun.value === "주야간보호") {
            item.suega = "(8시간)3등급수가";
            item.days = 25;
          }
          // item.suega = "직접입력";

          let matchingSuega = getSuega.value.find(
            (suega: { rank: string }) => suega.rank === item.suega
          );
          if (item.scode === "112") {
            item.price = ac(matchingSuega?.본인부담 * item.days);
          } else if (item.scode === "611") {
            item.price = ac((matchingSuega?.수가 - matchingSuega?.본인부담) * item.days);
          }
        } else if (!item.suega || !item.days) {
          item.suega = "직접입력";
          item.days = 1;
        }
      }
      if (!item.idx) {
        if (isTargetScode(item.scode)) {
          item.month = 12;
        } else {
          item.month = "";
        }
        item.amount =
          ac(nc(item.price || 0) * nc(item.person || 0) * nc(item.month || 0)) || "";
        const totalAmount = budInListWithRowspan.value
          .filter((bud) => bud.scode === item.scode) // item.scode와 일치하는 항목 필터링
          .reduce((sum, bud) => sum + nc(bud.amount || 0), 0); // 예산액 컬럼의 합계 계산

        // 예산액 컬럼을 합산된 값으로 업데이트
        budInListWithRowspan.value
          .filter((bud) => bud.scode === item.scode) // item.scode와 일치하는 항목만 업데이트
          .forEach((bud) => {
            bud.예산액 = ac(totalAmount); // 합산된 예산액을 업데이트
          });
        item.funding_src = item.funding_src_default;
        item.changeStatus = false;
      }else{
        item.changeStatus = true;
      }
      budOutListWithRowspan.value.forEach((item) => {
        if(!item.idx){
          item.changeStatus = false;
        }else{
          item.changeStatus = true;
        }
      })
    });

    budOutListWithRowspan.value.forEach((item) => {
      const totalAmount = budOutListWithRowspan.value
        .filter((bud) => bud.scode === item.scode) // item.scode와 일치하는 항목 필터링
        .reduce((sum, bud) => sum + nc(bud.amount || 0), 0); // 예산액 컬럼의 합계 계산

      // 예산액 컬럼을 합산된 값으로 업데이트
      budOutListWithRowspan.value
        .filter((bud) => bud.scode === item.scode) // item.scode와 일치하는 항목만 업데이트
        .forEach((bud) => {
          bud.예산액 = ac(totalAmount); // 합산된 예산액을 업데이트
        });

      if (!item.item && (item.scode === "1111" || item.scode === "1112")) {
        item.item = `password@@@#123`;
      }
    });



    mnameTotal.value = responseData?.mnameTotal;
    lnameTotal.value = responseData?.lnameTotal;

    return responseData;
  } catch (error) {
    console.error("errorMsg:", error);
  }
};
const realLaborPercent = ref();
const getBudgetDetailList1 = async () => {
  try {
    const response = await axios.get(
      `/api/getBudgetDetailList/${mainComp.value}/${mainCompBizGubun.value}/${mainYear.value}/${budNum.value}`
    );
    const responseData = response.data;
    budList.value = responseData?.result || [];
    budSumList.value = responseData?.sum;
    percent.value = responseData?.percent?.percentage || null;
    pr.value = responseData?.pr;
    laborOut.value = responseData.laborPercent.labor_out;
    laborIn.value = responseData.laborPercent.labor_in;
    //직접입력 추가
    laborPercent.value =
      parseFloat(
        (
          (laborOut.value / laborIn.value) *
          100
        ).toFixed(1)
      ) || 0;
    if (laborPercent.value === Infinity) {
      laborPercent.value = ""; // 값이 없으면 0으로 설정
    }
    realLaborPercent.value = laborPercent.value;
    budOutList.value = budList.value.filter(
      (item: { 구분: string }) => item.구분 === "세출"
    );
    budInList.value = budList.value.filter(
      (item: { 구분: string }) => item.구분 === "세입"
    );
    // budInList의 rowspan 계산
    budInListWithRowspan.value = calculateRowspan(budInList.value);
    budOutListWithRowspan.value = calculateRowspan(budOutList.value);
    //정원 디폴트로 집어넣기위함.
    budInListWithRowspan.value.forEach((item) => {
      if (isTargetScode(item.scode) && (!item.person || item.person === 0)) {
        item.person = w4cInfo.value?.personnel;
        item.month = 12;
      }

      if (item.scode === "112" || item.scode === "611") {
        if (!item.item) {
          // 값이 없는 경우.
          if (mainCompBizGubun.value === "공동생활가정") {
            item.suega = "1등급수가";
            item.days = 30;
          }
          if (mainCompBizGubun.value === "방문목욕") {
            item.suega = "차량이용(차량 내)수가";
            item.days = 5;
          }
          if (mainCompBizGubun.value === "방문요양") {
            item.suega = "180분수가";
            item.days = 26;
          }
          if (mainCompBizGubun.value === "요양시설") {
            item.suega = "2등급수가";
            item.days = 30;
          }
          if (mainCompBizGubun.value === "주야간보호") {
            item.suega = "(8시간)3등급수가";
            item.days = 25;
          }
          // item.suega = "직접입력";

          let matchingSuega = getSuega.value.find(
            (suega: { rank: string }) => suega.rank === item.suega
          );
          if (item.scode === "112") {
            item.price = ac(matchingSuega?.본인부담 * item.days);
          } else if (item.scode === "611") {
            item.price = ac((matchingSuega?.수가 - matchingSuega?.본인부담) * item.days);
          }
        } else if (!item.suega || !item.days) {
          item.suega = "직접입력";
          item.days = 1;
        }
      }
      if (!item.idx) {
        if (isTargetScode(item.scode)) {
          item.month = 12;
        } else {
          item.month = "";
        }
        item.amount =
          ac(nc(item.price || 0) * nc(item.person || 0) * nc(item.month || 0)) || "";
        const totalAmount = budInListWithRowspan.value
          .filter((bud) => bud.scode === item.scode) // item.scode와 일치하는 항목 필터링
          .reduce((sum, bud) => sum + nc(bud.amount || 0), 0); // 예산액 컬럼의 합계 계산

        // 예산액 컬럼을 합산된 값으로 업데이트
        budInListWithRowspan.value
          .filter((bud) => bud.scode === item.scode) // item.scode와 일치하는 항목만 업데이트
          .forEach((bud) => {
            bud.예산액 = ac(totalAmount); // 합산된 예산액을 업데이트
          });
        item.funding_src = item.funding_src_default;
        item.changeStatus = false;
      }else{
        item.changeStatus = true;
      }
      budOutListWithRowspan.value.forEach((item) => {
        if(!item.idx){
          item.changeStatus = false;
        }else{
          item.changeStatus = true;
        }
      })
    });

    budOutListWithRowspan.value.forEach((item) => {
      const totalAmount = budOutListWithRowspan.value
        .filter((bud) => bud.scode === item.scode) // item.scode와 일치하는 항목 필터링
        .reduce((sum, bud) => sum + nc(bud.amount || 0), 0); // 예산액 컬럼의 합계 계산

      // 예산액 컬럼을 합산된 값으로 업데이트
      budOutListWithRowspan.value
        .filter((bud) => bud.scode === item.scode) // item.scode와 일치하는 항목만 업데이트
        .forEach((bud) => {
          bud.예산액 = ac(totalAmount); // 합산된 예산액을 업데이트
        });

      if (!item.item && (item.scode === "1111" || item.scode === "1112")) {
        item.item = `password@@@#123`;
      }
    });

    mnameTotal.value = responseData?.mnameTotal;
    lnameTotal.value = responseData?.lnameTotal;

    return responseData;
  } catch (error) {
    console.error("errorMsg:", error);
  }
};
// const getBudgetSum = async () => {
//   try {
//     const response = await axios.get(
//       `/api/getBudgetSum/${mainComp.value}/${mainCompBizGubun.value}/${mainYear.value}/${budNum.value}`
//     );
//     const responseData = response.data;
//     laborOut.value = responseData.laborPercent.labor_out;
//     laborIn.value = responseData.laborPercent.labor_in;
//     if (responseData) {
//       laborPercent.value =
//         parseFloat(
//           (
//             (laborOut.value / laborIn.value) *
//             100
//           ).toFixed(1)
//         ) || 0;
//       if (laborPercent.value === Infinity) {
//         laborPercent.value = ""; // 값이 없으면 0으로 설정
//       }
//       budSumList.value = responseData.sum;
//     }
//     return responseData;
//   } catch (error) {
//     console.error("errorMsg:", error);
//   }
// };
const isTargetScode = (scode: string) => {
  return ["112", "113", "116", "611"].includes(scode);
};
const deleteBudgetDetail = async (item: any) => {
  try {
    const response = await axios.post(
      `/api/deleteBudgetDetail/${mainComp.value}/${mainCompBizGubun.value}/${mainYear.value}/${budNum.value}`,
      { item }
    );
    const result = response.data;
    if (!result) {
      alert("삭제에 실패하였습니다.");
    }else{
      mnameTotal.value = result?.mnameTotal;
      lnameTotal.value = result?.lnameTotal;
    }
  } catch (error) {
    console.error("errorMsg:", error);
  } finally {
      await getBudgetDetailList1();
  }
};


const saveBudgetDetail = async (item: any, index: number) => {
  try {
    let itemIdx = 0;
    let response = null;

    // 필수 항목이 모두 비어있는 경우 함수 종료
    if (
      !item.funding_src ||
      !item.month ||
      !item.person ||
      !item.price ||
      (!item.item && (!item.suega || !item.days))
    ) {
      if(!item.resrc_class){
        alert("빈 칸이 존재합니다.");
        return;
      }
    }
    if (
      item.구분 === "세입" &&
      item.suega !== "직접입력" &&
      (item.scode === "112" || item.scode === "611")
    ) {
      item.item = `${item.suega}X${item.days}일`;
      item.content = '';
    }else if(
      item.구분 === "세입" &&
      item.suega === "직접입력" &&
      (item.scode === "112" || item.scode === "611")){
        item.content = '기타'
      }
    if (item.idx) {
      itemIdx=item.idx;
      const test = budList.value.find((budItem) => budItem.idx === item.idx);
      if (
        test?.amount == item?.amount &&
        test?.funding_src == item?.funding_src &&
        test?.item == item?.item &&
        test?.month == item?.month &&
        test?.person == item?.person &&
        test?.price == item?.price &&
        test?.suega == item?.suega &&
        test?.days == item?.days
      ) {
        if(!item.resrc_class){
          return;
        }
      }
      if(item.scode === '1141' || item.scode === '1142' || item.scode === '1151' || item.scode === '1152'){
        item.amount = nc(item.price) * nc(item.month) * nc(item.person);
      }
      isLoadingMulti.value = true;
      response = await axios.post(`/api/updateBudgetDetail/manual/${mainComp.value}/${mainCompBizGubun.value}/${mainYear.value}/${budNum.value}`, { item });
      const result = response.data;
      if (result.success) {
        snackbar.value = true;
        mnameTotal.value = result?.mnameTotal;
        lnameTotal.value = result?.lnameTotal;
        const updatedIndex = budList.value.findIndex(
          (budItem) => budItem.idx === item.idx
        );
        if (updatedIndex !== -1) {
          budList.value[updatedIndex] = { ...item };
        }
        
      } else {
        alert("업데이트에 실패하였습니다.");
      }
    } else {
      // idx가 없으면 새로 삽입
      response = await axios.post(
        `/api/insertBudgetDetail/manual/${mainComp.value}/${mainCompBizGubun.value}/${mainYear.value}/${budNum.value}`,
        { item }
      );
      const result = response.data;
      if (result.result.success) {
        snackbar.value = true;
        mnameTotal.value = result?.mnameTotal;
        lnameTotal.value = result?.lnameTotal;
        item.idx = result.result.idx;
        itemIdx = item.idx;
        if (item.구분 === "세입") {
          budInListWithRowspan.value[index].idx = result.result.idx;
        } else {
          budOutListWithRowspan.value[index].idx = result.result.idx;
        }
      } else {
        alert("생성에 실패하였습니다.");
      }
    }
    if (
      item.구분 === "세입" &&
      (item.scode === "113" || item.scode === "413" || item.scode === "116")
    ) {
      defaultSaveBudget(item, itemIdx);
    }
  } catch (error) {
    console.error("errorMsg:", error);
  } finally {
    await getBudgetDetailList1();
    isLoadingMulti.value = false;
  }
};

const defaultSaveBudget = async (item: any, itemIdx: any) => {
  //디폴트값 부여.
  const item2 = {
    idx: 0,
    scode: "",
    item: "",
    month: "",
    person: "",
    price: "",
    funding_src: "",
    amount: 0,
    data_status: "",
    content: "",
    auto_in: "",
    구분: "세출",
  };

  let targetObject = [];
  if (item.scode === "113") {
    item2.scode = "311";
    targetObject = budOutListWithRowspan.value.find((f) => f.scode === "311");
    item2.item = targetObject ? targetObject["item"] : undefined;
    if(!item2.item){
      item2.item = '생계비';
    }
  } else if (item.scode === "413") {
    item2.scode = "312";
    targetObject = budOutListWithRowspan.value.find((f) => f.scode === "312");
    item2.item = targetObject ? targetObject["item"] : undefined;
    if(!item2.item){
      item2.item = '생계비(보조금)';
    }
  } else if (item.scode === "116") {
    item2.scode = "315";
    targetObject = budOutListWithRowspan.value.find((f) => f.scode === "315");
    item2.item = targetObject ? targetObject["item"] : undefined;
    if(!item2.item){
      item2.item = '의료비';
    }
  }
  
  item2.amount = item.amount;
  item2.price = item.price;
  item2.person = item.person;
  item2.month = item.month;
  item2.funding_src = item.funding_src;

  if (item.scode === "113") {
    item2.scode = "311";
    targetObject = budOutListWithRowspan.value.find((f) => f.scode === "311" && parseFloat(f.auto_in) === itemIdx);
  } else if (item.scode === "413") {
    item2.scode = "312";
    targetObject = budOutListWithRowspan.value.find((f) => f.scode === "312" && parseFloat(f.auto_in) === itemIdx);
  } else if (item.scode === "116") {
    item2.scode = "315";
    targetObject = budOutListWithRowspan.value.find((f) => f.scode === "315" && parseFloat(f.auto_in) === itemIdx);
  }
  item2.idx = targetObject ? targetObject["idx"] : undefined;
  item2.auto_in = targetObject ? targetObject["auto_in"] : undefined;
  console.log(itemIdx, item2.auto_in)
  if (itemIdx && item2.auto_in) {
    const response = await axios.post(`/api/updateBudgetDetail/auto/${mainComp.value}/${mainCompBizGubun.value}/${mainYear.value}/${budNum.value}`, { item2 });
    const result = response.data;
    if (result.success) {
      snackbar.value = true;
      mnameTotal.value = result?.mnameTotal;
      lnameTotal.value = result?.lnameTotal;
    } else {
      alert(`${item2.item} 자동 업데이트에 실패하였습니다.`);
    }
  } else {
    item2.auto_in = item.idx;
    const response = await axios.post(
      `/api/insertBudgetDetail/auto/${mainComp.value}/${mainCompBizGubun.value}/${mainYear.value}/${budNum.value}`,
      { item2 }
    );
    const result = response.data;
    if (result.result.success) {
      snackbar.value = true;
      mnameTotal.value = result?.mnameTotal;
      lnameTotal.value = result?.lnameTotal;
    } else {
      alert(`${item2.item} 자동 생성에 실패하였습니다.`);
    }
  }
  await getBudgetDetailList1();
};

const clickEffectIndex = ref(null) as any; // 클릭된 index 추적 변수

const handleClick = async (item: any, index: any) => {
  clickEffectIndex.value = index; // 클릭 효과 설정
  await saveBudgetDetail(item, index); // 저장 함수 호출

  // 클릭 효과를 일시적으로 유지 후 제거
  setTimeout(() => {
    clickEffectIndex.value = null;
  }, 100); // 200ms 후 클릭 효과 제거
};

const saveMultiBudgetDetail = async (scode: any) => {
  try {
    let response = null;

    const items = budOutListWithRowspan.value.filter((bud) => bud.scode === scode); // scode와 일치하는 항목만 업데이트

    const requiredFields = ["amount", "funding_src", "month", "person", "price", "item"];

    const hasEmptyFields = items.some((item) =>
      requiredFields.some((field) => !item[field] || item[field].toString().trim() === "")
    );
    const hasInvalidItem = items.some(
      (item) => item.item && item.item.includes("password@@@#123")
    );

    if (items.length === 0 || hasEmptyFields || hasInvalidItem) {
      alert("빈 칸이 존재합니다.");
      return;
    }

    isLoadingMulti.value = true;
    response = await axios.post(
      `/api/saveMultiBudgetDetail/${mainComp.value}/${mainCompBizGubun.value}/${mainYear.value}/${budNum.value}`,
      { items }
    );
    const result = response.data;
    if (result?.result?.success) {
      mnameTotal.value = result?.mnameTotal;
      lnameTotal.value = result?.lnameTotal;
      const response2 = await axios.get(
        `/api/getBudgetDetailList/${mainComp.value}/${mainCompBizGubun.value}/${mainYear.value}/${budNum.value}`
      );
      const responseData = response2.data;
      budList.value = responseData.result;
      laborOut.value = responseData.laborPercent.labor_out;
    laborIn.value = responseData.laborPercent.labor_in;
      laborPercent.value =
        parseFloat(
          (
            (laborOut.value / laborIn.value) *
            100
          ).toFixed(1)
        ) || 0;
      if (laborPercent.value === Infinity) {
        laborPercent.value = ""; // 값이 없으면 0으로 설정
      }
      budOutList.value = budList.value.filter(
        (item: { 구분: string }) => item.구분 === "세출"
      );
      budOutListWithRowspan.value = calculateRowspan(budOutList.value);
      snackbar.value = true;
    } else {
      alert("생성에 실패하였습니다.");
    }
  } catch (error) {
    console.error("errorMsg:", error);
  } finally {
    await getBudgetDetailList1();
    isLoadingMulti.value = false;
    if( (laborPercent.value) <  (percent.value)){
      alert(`※주의※ \n인건비 지출 비율이 약 ${ac(percent.value-laborPercent.value)} % 부족합니다.`);
    }
  }
};

const handleMultiClick = async (scode: any, index: any) => {
  clickEffectIndex.value = index; // 클릭 효과 설정
  await saveMultiBudgetDetail(scode); // 저장 함수 호출

  // 클릭 효과를 일시적으로 유지 후 제거
  setTimeout(() => {
    clickEffectIndex.value = null;
  }, 100); // 200ms 후 클릭 효과 제거
};

function addComma(number: number) {
  // 입력이 숫자인지 확인 후 숫자로 강제 변환
  const num = Number(number);
  if (isNaN(num)) return number; // 숫자가 아닌 경우 그대로 반환
  if (num === 0) return "0"; // 0이 입력된 경우 "-" 반환
  // 숫자인 경우 컴마를 추가한 문자열 반환
  return num.toLocaleString();
}
watch(
  () => [
    store.state.mainComp,
    store.state.mainCompBizGubun,
    store.state.mainYear,
    store.state.mainBudNum,
    store.state.mainGubun,
  ],
  async ([newMainComp, newMainCompBizGubun, newMainYear, newBudNum, newMainGubun]) => {
    mainComp.value = newMainComp;
    mainCompBizGubun.value = newMainCompBizGubun;
    mainYear.value = newMainYear;
    budNum.value = newBudNum;
    mainGubun.value = newMainGubun;
    isLoading.value = true;
    await getBudgetDetailList();
    isLoading.value = false;
  }
);
watch(
  () => store.state.NewBudgetStatus, // 감시할 상태
  (newValue) => {
    if (newValue === 'not-value') {
      router.push('/MngBudgetList').then(() => {
        window.location.reload(); // 페이지 새로고침
      });
    }
  }
);
onMounted(async () => {
  mainComp.value = store.state.mainComp;
  mainCompBizGubun.value = store.state.mainCompBizGubun;
  mainYear.value = store.state.mainYear;
  budNum.value = store.state.mainBudNum;
  mainGubun.value = store.state.mainGubun;
  isLoading.value = true;
  await getBudgetDetailList();
  isLoading.value = false;
});
function clearFormat(item: any, key: any) {
  // 인풋의 값을 컴마 없이 만듭니다.

  // 값이 0이면 빈 값으로 설정하여 placeholder를 보이도록 합니다.
  if (
    item[key] === "0" ||
    item[key] === "0.0" ||
    item[key] === null ||
    item[key] === "" ||
    item[key] === undefined ||
    item[key] === 0 ||
    item[key] === "-0" ||
    !item[key]
  ) {
    item[key] = "";
  } else {
    item[key] = nc(item[key]) || "";
    if(item.scode === '1141' || item.scode === '1142' || item.scode === '1151' || item.scode === '1152'){
      if(!item.amount){
          item.amount =
          ac(nc(item.price || 0) * nc(item.person || 0) * nc(item.month || 0)) || "";
        }else{
          return;
        }
      }else{
        item.amount =
        ac(nc(item.price || 0) * nc(item.person || 0) * nc(item.month || 0)) || "";
        }
      
    if (item.구분 === "세입") {
      const totalAmount = budInListWithRowspan.value
        .filter((bud) => bud.scode === item.scode) // item.scode와 일치하는 항목 필터링
        .reduce((sum, bud) => sum + nc(bud.amount || 0), 0); // 예산액 컬럼의 합계 계산

      // 예산액 컬럼을 합산된 값으로 업데이트
      budInListWithRowspan.value
        .filter((bud) => bud.scode === item.scode) // item.scode와 일치하는 항목만 업데이트
        .forEach((bud) => {
          bud.예산액 = ac(totalAmount); // 합산된 예산액을 업데이트
        });
    } else {

      const totalAmount2 = budOutListWithRowspan.value
        .filter((bud) => bud.scode === item.scode) // item.scode와 일치하는 항목 필터링
        .reduce((sum, bud) => sum + nc(bud.amount || 0), 0); // 예산액 컬럼의 합계 계산

      // 예산액 컬럼을 합산된 값으로 업데이트
      budOutListWithRowspan.value
        .filter((bud) => bud.scode === item.scode) // item.scode와 일치하는 항목만 업데이트
        .forEach((bud) => {
          bud.예산액 = ac(totalAmount2); // 합산된 예산액을 업데이트
        });
    }
  }
}
function applyFormat(item: any, key: any) {

  // 값이 비어있으면 0으로 설정
  if (
    item[key] === "" ||
    item[key] === null ||
    item[key] === undefined ||
    item[key] === "-0"
  ) {
    item[key] = "";
  } else {
    // 포커스가 해제되면 천 단위로 컴마를 추가
    item.amount =
      ac(nc(item.price || 0) * nc(item.person || 0) * nc(item.month || 0)) || "";
    if (item.구분 === "세입") {
      const totalAmount = budInListWithRowspan.value
        .filter((bud) => bud.scode === item.scode) // item.scode와 일치하는 항목 필터링
        .reduce((sum, bud) => sum + nc(bud.amount || 0), 0); // 예산액 컬럼의 합계 계산

      // 예산액 컬럼을 합산된 값으로 업데이트
      budInListWithRowspan.value
        .filter((bud) => bud.scode === item.scode) // item.scode와 일치하는 항목만 업데이트
        .forEach((bud) => {
          bud.예산액 = ac(totalAmount); // 합산된 예산액을 업데이트
        });
    } else {
      const totalAmount2 = budOutListWithRowspan.value
        .filter((bud) => bud.scode === item.scode) // item.scode와 일치하는 항목 필터링
        .reduce((sum, bud) => sum + nc(bud.amount || 0), 0); // 예산액 컬럼의 합계 계산

      // 예산액 컬럼을 합산된 값으로 업데이트
      budOutListWithRowspan.value
        .filter((bud) => bud.scode === item.scode) // item.scode와 일치하는 항목만 업데이트
        .forEach((bud) => {
          bud.예산액 = ac(totalAmount2); // 합산된 예산액을 업데이트
        });
    }
  }
}

function commaBlur(item: any, key: any) {
  item[key] = ac(item[key]);
}
function suegaInputChange(item: any, key: any) {
  if (key === "suega" && item[key] === "직접입력") {
    item.item = "";
    item.days = 1;
  } else if (key === "suega" || key === "days") {
    let matchingSuega = getSuega.value.find(
      (suega: { rank: string }) => suega.rank === item.suega
    );
    if (item.scode === "112") {
      item.price = ac(matchingSuega.본인부담 * item.days);
    } else if (item.scode === "611") {
      item.price = ac((matchingSuega?.수가 - matchingSuega?.본인부담) * item.days);
    }
    item.amount =
      ac(nc(item.price || 0) * nc(item.person || 0) * nc(item.month || 0)) || "";
    if (item.구분 === "세입") {
      const totalAmount = budInListWithRowspan.value
        .filter((bud) => bud.scode === item.scode) // item.scode와 일치하는 항목 필터링
        .reduce((sum, bud) => sum + nc(bud.amount || 0), 0); // 예산액 컬럼의 합계 계산

      // 예산액 컬럼을 합산된 값으로 업데이트
      budInListWithRowspan.value
        .filter((bud) => bud.scode === item.scode) // item.scode와 일치하는 항목만 업데이트
        .forEach((bud) => {
          bud.예산액 = ac(totalAmount); // 합산된 예산액을 업데이트
        });
    } else {
      const totalAmount2 = budOutListWithRowspan.value
        .filter((bud) => bud.scode === item.scode) // item.scode와 일치하는 항목 필터링
        .reduce((sum, bud) => sum + nc(bud.amount || 0), 0); // 예산액 컬럼의 합계 계산

      // 예산액 컬럼을 합산된 값으로 업데이트
      budOutListWithRowspan.value
        .filter((bud) => bud.scode === item.scode) // item.scode와 일치하는 항목만 업데이트
        .forEach((bud) => {
          bud.예산액 = ac(totalAmount2); // 합산된 예산액을 업데이트
        });
    }
  }
}

// 저장 아이콘 조건 계산
const iconConditions = computed(() => {
  return budOutListWithRowspan.value.map((item, index) => {
    const rowspanIndices = Array.from(
      { length: item.rowspan?.예산액 || 0 },
      (_, i) => index + i
    ); // rowspan 범위 인덱스 계산

    const allIndicesValid = rowspanIndices.every((rowIndex) => {
      const rowItem = budOutListWithRowspan.value[rowIndex];
      return (
        (
          rowItem &&
          rowItem.item &&
          rowItem.item !== "password@@@#123" &&
          rowItem.item !== "선택(퇴직금)" &&
          rowItem.item !== "선택(사회보험부담금)" &&
          rowItem.price &&
          rowItem.person &&
          rowItem.month &&
          rowItem.funding_src
        )
      );
    });

    const allIndicesHaveIdx = rowspanIndices.every((rowIndex) => {
      const rowItem = budOutListWithRowspan.value[rowIndex];
      return rowItem?.idx; // 모든 항목의 `idx` 값이 존재해야 함
    });

    const allIndicesHaveChangeStatus = rowspanIndices.every((rowIndex) => {
      const rowItem = budOutListWithRowspan.value[rowIndex];
      return rowItem?.changeStatus; // 모든 항목의 `idx` 값이 존재해야 함
    });


    return {
      showCheckedIcon: allIndicesHaveIdx && allIndicesValid && allIndicesHaveChangeStatus, // 저장 완료 (모든 조건 + 모든 idx 존재)
      showSaveIcon: ((!allIndicesHaveIdx && !allIndicesHaveChangeStatus) || (allIndicesHaveIdx && !allIndicesHaveChangeStatus))  && allIndicesValid, // 저장 가능 (모든 조건 충족, 일부 idx 없음)
      showDisabledIcon: !allIndicesValid, // 비활성화 상태 (조건 불충족)
    };
  });
});

function nc(value: any) {
  // 숫자 변환 시 문제가 발생하지 않도록 문자열과 숫자를 처리
  const num = parseFloat(value.toString().replace(/,/g, ""));
  return isNaN(num) ? 0 : num;
}
function nc_num(value: any) {
  // 숫자 변환 시 문제가 발생하지 않도록 문자열과 숫자를 처리
  const num = value.toString().replace(/,/g, "");
  return num;
}
function ac(value: any) {
  let num = Number(value);
  if (isNaN(num)) return null;
  if (num === 0) return "";
  return num.toLocaleString();
}
const filteredMnameTotal = computed(() => {
  return (item: any) => {
    return mnameTotal.value.find(
      (total: any) =>
        total.lcode === item.lcode &&
        total.mcode === item.mcode &&
        total.구분 === item.구분
    );
  };
});
const filteredLnameTotal = computed(() => {
  return (item: any) => {
    return lnameTotal.value.find(
      (total: any) =>
        total.lcode === item.lcode &&
        total.구분 === item.구분
    );
  };
});
function a1cut(value: any) {
  let num = Number(value);
  if (isNaN(num)) return null;
  if (num === 0) return 0;
  num = Math.floor(num / 10) * 10;
  return num;
}
watch(
  () => budOutListWithRowspan.value.filter((item: any) => 
    item.amount &&
    ['1111', '1121', '1131'].includes(item.scode) // 여러 값 중 하나를 포함하는지 체크
  ), 
  (newAmounts) => {
    if(isLoading.value){
      return;
    }
    const nameoji = newAmounts
      .filter(newAmount => (newAmount.scode === '1121' || newAmount.scode === '1131' ) && !newAmount.resrc_class) // 조건 추가
      .reduce((sum, newAmount) => sum + nc(newAmount.amount), 0) || 0;

    const averageMonth = newAmounts
      .filter(item => (item.scode === '1111') && !item.resrc_class && item.month !== undefined ) // 조건 추가
      .reduce((acc, item, _, array) => acc + item.month / array.length, 0) || 0; // 평균 계산
    
      const averageMonthBoss = newAmounts
      .filter(item => (item.scode === '1111') && 
            item.resrc_class &&
            item.month !== undefined
      ) // 조건 추가
      .reduce((acc, item, _, array) => acc + item.month / array.length, 0) || 0; // 평균 계산

    // scode가 1111 또는 1112인 항목 중 resrc_class가 없는 항목의 amount 합계 계산
    const labor = newAmounts
      .filter(newAmount => (newAmount.scode === '1111') && !newAmount.resrc_class) // 조건 추가
      .reduce((sum, newAmount) => sum + nc(newAmount.amount), 0) || 0;


    let yeon = a1cut(pr.value.yeon * labor)||0;
    let gun = a1cut(pr.value.gun * labor)||0;
    let jang = a1cut(pr.value.jang *(pr.value.gun * labor))||0;
    let san = a1cut(pr.value.san * labor)||0;
    let go = a1cut((pr.value.go+pr.value.sil) * labor)||0;
    let sil = 0;

    const labor_insu = (yeon + gun + go + jang + san + sil) || 0;
    const labor_out = (labor/averageMonth) || 0;
    const laborBoss = newAmounts
    .filter(newAmount => newAmount.resrc_class) 
    .reduce((sum, newAmount) => sum + nc(newAmount.amount), 0) || 0;
    let yeonB = a1cut(pr.value.yeon * laborBoss)||0;
    let gunB = a1cut(pr.value.gun * laborBoss)||0;
    let jangB = a1cut(pr.value.jang *(pr.value.gun * laborBoss))||0;
    let sanB = 0;
    let goB = 0;
    let silB = a1cut(pr.value.sil * laborBoss
    )||0;

    const laborBoss_insu = (yeonB + gunB + goB + jangB + sanB + silB) || 0;

    const laborBoss_out = (laborBoss/averageMonthBoss) || 0;

    laborOut.value = nameoji+ labor + labor_insu + labor_out + laborBoss + laborBoss_insu + laborBoss_out
    // console.log('nameoji: ', ac(nameoji) , 'labor: ', ac(labor) , 'labor_insu: ', ac(labor_insu), 'labor_out: ', ac(labor_out), 'laborBoss: ', ac(laborBoss),
    // 'laborBoss_insu: ',ac(laborBoss_insu), 'laborBoss_out: ', ac(laborBoss_out)
    // )

    laborPercent.value =
      parseFloat(
        (
          (laborOut.value / laborIn.value) *
          100
        ).toFixed(1)
      ) || 0;
    if (laborPercent.value === Infinity) {
      laborPercent.value = ""; // 값이 없으면 0으로 설정
    }
  },
  { deep: true } // 배열 내부 객체의 변경을 감지하기 위해 deep watch 설정
);
watch(
  () => budInListWithRowspan.value.filter((item: any) => 
    item.amount &&
    ['112', '611', '613'].includes(item.scode) // 여러 값 중 하나를 포함하는지 체크
  ), 
  (newAmounts) => {
    if(isLoading.value){
      return;
    }

    // newAmounts 배열에서 amount 값들을 합산

    laborIn.value = newAmounts.reduce((sum, newAmount) => sum + (nc(newAmount.amount)), 0);
    laborPercent.value =
      parseFloat(
        (
          (laborOut.value / laborIn.value) *
          100
        ).toFixed(1)
      ) || 0;
    if (laborPercent.value === Infinity) {
      laborPercent.value = ""; // 값이 없으면 0으로 설정
    }
  },
  { deep: true } // 배열 내부 객체의 변경을 감지하기 위해 deep watch 설정
);


</script>
<style>
.budDetailListTable {
  max-width: 100%;
  width: 100%;
  min-width: 1230px;
  height: 100%;
  border-collapse: collapse;
}
.budDetailListTable th {
  position: sticky; /* 헤더 고정 */
  top: 0; /* 상단에 고정 */
  z-index: 10; /* 헤더가 다른 요소 위에 위치하도록 설정 */
  font-size: 15px;
  padding: 8px 5px;
  height: 100%;
  background-color: rgb(196, 208, 255);
  border-left: 1px solid #b4b4b4;
  border-right: 1px solid #b4b4b4;
  border-bottom: 1px solid #b4b4b4;
  color: #282828;
}
.budDetailListTable td {
  border: 1px solid #b4b4b4 !important;
  padding: 3px 5px;
  height: 45px;
  color: #282828;
}
.budDetailListTable .budListTr {
  font-size: 15px;
}
.bgColorGray {
  background-color: #f1f1f1bb;
}
.budDetailInput {
  width: 100%;
  min-height: 28px;
  height: 28px;
  text-align: right;
  outline: 1px solid #efefef;
  padding-right: 5px;
  background-color: #fff;
}
.budDetailInput:focus {
  outline: 1px solid #969696;
}
.budDetailSelect {
  width: 100%;
  height: 28px;
  min-height: 28px;
  text-align: center;
  outline: 1px solid #efefef;
  background-color: #fff;
}
.budDetailSelect:focus {
  outline: 1px solid #969696;
}

.budDetailListTable tbody .budListTr:hover {
  background-color: #d2d2d2;
}
.budDetailListTable thead tr th:nth-child(1) {
  width: 3%;
}
.budDetailListTable thead tr th:nth-child(2) {
  width: 10%;
}
.budDetailListTable thead tr th:nth-child(3) {
  width: 10%;
}
.budDetailListTable thead tr th:nth-child(4) {
  width: 10%;
}
.budDetailListTable thead tr th:nth-child(5) {
  width: 4.125%;
}
.budDetailListTable thead tr th:nth-child(6) {
  min-width: 200px;
  width: 7.125%;
}
.budDetailListTable thead tr th:nth-child(7) {
  width: 7.125%;
}
.budDetailListTable thead tr th:nth-child(8) {
  width: 7.125%;
}
.budDetailListTable thead tr th:nth-child(9) {
  width: 7.125%;
}
.budDetailListTable thead tr th:nth-child(10) {
  width: 7.125%;
}
.budDetailListTable thead tr th:nth-child(11) {
  width: 7.125%;
}
.budDetailListTable thead tr th:nth-child(12) {
  width: 4.125%;
  cursor: pointer;
}

.budgetHoverBtn:hover {
  transform: scale(1.1); /* 호버 시 살짝 확대 */
  transition: background-color 0.1s ease, transform 0.1s ease;
}
.defaultBgColor {
  background-color: #fff;
}
.clicked {
  transform: scale(0.9) !important; /* 클릭 시 크기 살짝 확대 */
  transition: background-color 0.1s ease, transform 0.1s ease;
}
.input-container {
  display: flex; /* flexbox로 배치 */
  justify-content: flex-start; /* 왼쪽 정렬 */
  gap: 5px; /* 요소들 간의 간격 */
}

.budDetailInput {
  padding: 5px; /* 입력 필드 안의 여백 */
}
.inMok {
  background-color: #e8ecff65;
}
.outMok {
  background-color: #ffe2e24f;
}
.budgetSaveBtn{
  padding: 0 !important;
  margin: 0;
}
</style>

<template>
  <v-container >

    <v-row v-if="!isLoading" >
      <v-col xs="2" sm="6" md="4" lg="3" xl="3" cols="12">
        <v-card>
          <v-list density="compact" nav style="height: 70vh; overflow-y: auto; padding: 0;" :style="{ backgroundColor: memberInfoListArrBefore.length === 0 ? '#d2d2d2' : '#fff' }">
            <!--배열에 전역변수기관과 일치하는 기관 명이 있는 경우-->
            <v-list-item class="activeClick" style="position: sticky; top: 0; z-index: 2; background-color: #ced4da;">
              <b>미입력 ({{ memberInfoListArrBefore.length }})</b>
            </v-list-item>
            <template v-if="memberInfoListArrBefore.some(mb => `${mb.mb_name}(${mb.memid})` === store.state.mainComp)">
              <template v-for="(mb, index) in memberInfoListArrBefore" :key="index">
                <v-list-item v-if="`${mb.mb_name}(${mb.memid})` === store.state.mainComp" @click="clickCompInfo(mb.mb_name,mb.memid)" style="height:42px; padding: 0 25px 2px 20px !important;" class="activeClick tal">
                    ✔ {{ mb.mb_name }}({{ mb.memid }})
                </v-list-item>
              </template>
          </template>
          <template v-else-if="store.state.mainComp === null">
            <template v-for="(mb, index) in memberInfoListArrBefore" :key="index">
                <v-list-item @click="clickCompInfo(mb.mb_name,mb.memid)" style="height:42px; padding: 0 25px 2px 20px !important;" class="activeClick tal">
                    {{ index + 1 }}. {{ mb.mb_name }}({{ mb.memid }}) 
                </v-list-item>
              </template>
          </template>
        </v-list>
      </v-card>
      </v-col>
       <v-col xs="2" sm="6" md="4" lg="3" xl="3" cols="12">
        <v-card>
          <v-list density="compact" nav style="height: 70vh; overflow-y: auto; padding: 0;" :style="{ backgroundColor: memberInfoInoutend.length === 0 ? '#d2d2d2' : '#fff' }">
            <!--배열에 전역변수기관과 일치하는 기관 명이 있는 경우-->
            <v-list-item class="activeClick" style="position: sticky; top: 0; z-index: 2; background-color: #c5bedd;">
              <b>입력 ({{ memberInfoInoutend.length }})</b>
            </v-list-item>
            <template v-if="memberInfoInoutend.some(mb => `${mb.mb_name}(${mb.memid})` === store.state.mainComp)">
              <template v-for="(mb, index) in memberInfoInoutend" :key="index">
                <v-list-item v-if="`${mb.mb_name}(${mb.memid})` === store.state.mainComp" @click="clickCompInfo(mb.mb_name,mb.memid)" style="height:42px; padding: 0 25px 2px 20px !important;" class="activeClick tal">
                  <span>✔ {{ mb.mb_name }}({{ mb.memid }})  </span>
                  <span style="float: right;">{{ formatDate(mb.rep_date) }}</span> 
                </v-list-item>
              </template>
          </template>
          <template v-else-if="store.state.mainComp === null">
            <template v-for="(mb, index) in memberInfoInoutend" :key="index">
                <v-list-item @click="clickCompInfo(mb.mb_name,mb.memid)" style="height:42px; padding: 0 25px 2px 20px !important;" class="activeClick tal">
                  <span> {{ index + 1 }}. {{ mb.mb_name }}({{ mb.memid }}) </span>
                  <span style="float: right;">{{ formatDate(mb.rep_date) }}</span> 
                </v-list-item>
              </template>
          </template>
        </v-list>

      </v-card>
      </v-col>
       <v-col xs="2" sm="6" md="4" lg="3" xl="3" cols="12">
        <v-card>
          <v-list density="compact" nav style="height: 70vh; overflow-y: auto; padding: 0;" :style="{ backgroundColor: memberInfoEditend.length === 0 ? '#d2d2d2' : '#fff' }">
            <!--배열에 전역변수기관과 일치하는 기관 명이 있는 경우-->
            <v-list-item class="activeClick" style="position: sticky; top: 0; z-index: 2; background-color: #82b3dc;">
              <b>조정 ({{ memberInfoEditend.length }})</b>
            </v-list-item>
            <template v-if="memberInfoEditend.some(mb => `${mb.mb_name}(${mb.memid})` === store.state.mainComp)">
              <template v-for="(mb, index) in memberInfoEditend" :key="index">
                <v-list-item v-if="`${mb.mb_name}(${mb.memid})` === store.state.mainComp" @click="clickCompInfo(mb.mb_name,mb.memid)" style="height:42px; padding: 0 25px 2px 20px !important;" class="activeClick tal">
                  <span>✔ {{ mb.mb_name }}({{ mb.memid }})  </span>
                  <span style="float: right;">{{ formatDate(mb.rep_date) }}</span> 
                </v-list-item>
              </template>
          </template>
          <template v-else-if="store.state.mainComp === null">
            <template v-for="(mb, index) in memberInfoEditend" :key="index">
                <v-list-item @click="clickCompInfo(mb.mb_name,mb.memid)" style="height:42px; padding: 0 25px 2px 20px !important;" class="activeClick tal">
                  <span> {{ index + 1 }}. {{ mb.mb_name }}({{ mb.memid }}) </span>
                  <span style="float: right;">{{ formatDate(mb.rep_date) }}</span> 
                </v-list-item>
              </template>
          </template>
        </v-list>

          
      </v-card>
      </v-col>
       <v-col xs="2" sm="6" md="4" lg="3" xl="3" cols="12">
        <v-card>
          <v-list density="compact" nav style="height: 70vh; overflow-y: auto; padding: 0;" :style="{ backgroundColor: memberInfoPrinting.length === 0 ? '#d2d2d2' : '#fff' }">
            <!--배열에 전역변수기관과 일치하는 기관 명이 있는 경우-->
            <v-list-item class="activeClick" style="position: sticky; top: 0; z-index: 2; background-color: #57b6ae;">
              <b>출력 ({{ memberInfoPrinting.length }})</b>
            </v-list-item>
            <template v-if="memberInfoPrinting.some(mb => `${mb.mb_name}(${mb.memid})` === store.state.mainComp)">
              <template v-for="(mb, index) in memberInfoPrinting" :key="index">
                <v-list-item v-if="`${mb.mb_name}(${mb.memid})` === store.state.mainComp" @click="clickCompInfo(mb.mb_name,mb.memid)" style="height:42px; padding: 0 25px 2px 20px !important;" class="activeClick tal">
                  <span>✔ {{ mb.mb_name }}({{ mb.memid }})  </span>
                  <span style="float: right;">{{ formatDate(mb.rep_date) }}</span> 
                </v-list-item>
              </template>
          </template>
          <template v-else-if="store.state.mainComp === null">
            <template v-for="(mb, index) in memberInfoPrinting" :key="index">
                <v-list-item @click="clickCompInfo(mb.mb_name,mb.memid)" style="height:42px; padding: 0 25px 2px 20px !important;" class="activeClick tal">
                  <span> {{ index + 1 }}. {{ mb.mb_name }}({{ mb.memid }}) </span>
                  <span style="float: right;">{{ formatDate(mb.rep_date) }}</span> 
                </v-list-item>
              </template>
          </template>
        </v-list>

      </v-card>
      </v-col>
    </v-row>
    <v-row v-else>
      로딩중입니다.
      </v-row>
  </v-container>
</template>
<script setup lang="ts">
import { computed, onMounted, ref, watch } from 'vue';
import axios from 'axios';
import store from '@/store';
import router from '@/router';
const isLoading = ref(false);

// comp선택 or 셀렉트 할 때, 유동적으로 변하게 가능함.
const clickCompInfo = async(compName: string, compId: string) => {
  store.commit('setMainComp', `${compName}(${compId})`);
  await router.push('/MngInout');
}
const mainComp = ref('');
const changeMainComp = computed(() => {
  //레이아웃 date를 바꾼 경우 상품입고일도 동기화
  const mainCompComputed = store.state.mainComp
  return mainCompComputed;
});

watch(changeMainComp, (newValue: any) => {
  //레이아웃 date를 바꾼 경우 상품입고일도 동기화
  if (newValue) {
    mainComp.value = newValue;
  }
});


// comp선택 or 셀렉트 코드 끝

// 레이아웃 메인데이트 변경 시, 감지
const setMainStartDate = computed(() => {
  const mainStartDateComputed = store.state.mainStartDate
  return mainStartDateComputed;
});

watch(setMainStartDate, async (newValue: any) => {
  if (newValue) {
    await getMemReportInfo();
    // await getMemReportInfoBefore();
    await getMemberInfo();

    // const memidArr = memberInfoListArr.value.map(item => item.memid);
    // memberInfoListArrBefore.value = memberInfoListArrBefore.value.filter(item => {
    //   return !memidArr.includes(item.memid);
    // });
  }
});

const setMainEndDate = computed(() => {
  const mainEndDateComputed = store.state.mainEndDate
  return mainEndDateComputed;
});

watch(setMainEndDate, async (newValue: any) => {
  if (newValue) {
    await getMemReportInfo();
    // await getMemReportInfoBefore();
    await getMemberInfo();

    // const memidArr = memberInfoListArr.value.map(item => item.memid);
    // memberInfoListArrBefore.value = memberInfoListArrBefore.value.filter(item => {
    //   return !memidArr.includes(item.memid);
    // });
  }
});
// 감지 후, get메서드 실행
// 한달을 빼는 함수
// function subtractOneMonth(dateString: any) {
//   // yyyy-mm 형식의 문자열을 Date 객체로 변환
//   const date = new Date(dateString);

//   // 현재 월에서 1을 빼고 새로운 Date 객체 생성
//   const newDate = new Date(date.getFullYear(), date.getMonth() - 1, date.getDate());

//   // 월이 음수가 되면 연도를 조정하고 월을 12로 설정
//   if (newDate.getMonth() === -1) {
//     newDate.setFullYear(date.getFullYear() - 1);
//     newDate.setMonth(11); // 12월로 설정
//   }

//   // 새로운 날짜를 yyyy-mm 형식의 문자열로 변환하여 반환
//   const year = newDate.getFullYear();
//   const month = String(newDate.getMonth() + 1).padStart(2, '0');

//   return `${year}-${month}`;
// }
function formatDate(inputDate: any): string {
  const date = new Date(inputDate);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');

  return `${year}-${month}-${day}`;
}
function formatyyyymm(inputDate: any): string {
  const date = new Date(inputDate);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');

  return `${year}-${month}`;
}
function compareNames(a:any, b:any) {
  const nameA = a.mb_name.toUpperCase(); // 대소문자 구분 없이 비교하기 위해 대문자로 변환
  const nameB = b.mb_name.toUpperCase();

  if (nameA < nameB) {
    return -1;
  }
  if (nameA > nameB) {
    return 1;
  }
  return 0; // 이름이 같은 경우
}

const memberInfoListArrBefore = ref([] as any[]);
const memberInfoListArr = ref([] as any[]);
const memberInfoInoutend = ref([] as any[]);
const memberInfoEditend = ref([] as any[]);
const memberInfoPrinting = ref([] as any[]);

const getMemReportInfo = async () => {
  isLoading.value = true;
  try {
    const response = await axios.get(`/api/getMemReportInfo/${formatyyyymm(store.state.mainStartDate)}/${store.state.MainAdminName}`);
    const responseData = response.data;

    if (Array.isArray(responseData)) {
      memberInfoListArr.value = responseData;
      memberInfoInoutend.value = responseData.filter((item, index, arr) => {
        return item.scode === 'inoutend' && arr.findIndex(i => i.memid === item.memid) === index;
      });

      memberInfoEditend.value = responseData.filter((item, index, arr) => {
        return item.scode === 'editend' && arr.findIndex(i => i.memid === item.memid) === index;
      });

      memberInfoPrinting.value = responseData.filter((item, index, arr) => {
        return item.scode === 'printing' && arr.findIndex(i => i.memid === item.memid) === index;
      });
      
      memberInfoListArrBefore.value = responseData.filter((item, index, arr) => {
        return item.scode === 'nonCode' && arr.findIndex(i => i.memid === item.memid) === index;
      });
      memberInfoListArrBefore.value.sort(compareNames);

      return responseData;
    } else {
      console.error('에러가 발생했습니다.', responseData);
    }
  } catch (error) {
    console.error('errorMsg:', error);
  }finally{
    isLoading.value = false;
  }
};

// const getMemReportInfoBefore = async () => {
//   isLoading.value = true;

//   try {
//     const response = await axios.get(`/api/getMemReportInfo/${subtractOneMonth(store.state.mainStartDate)}`);
//     const responseData = response.data;
//     if (Array.isArray(responseData)) {
//       memberInfoListArrBefore.value = responseData.filter((item, index, arr) => {
//         return item.scode === 'printing' && arr.findIndex(i => i.memid === item.memid) === index;
//       });
//       memberInfoListArrBefore.value.sort(compareNames);
//       return responseData;
//     } else {
//       console.error('에러가 발생했습니다.', responseData);
//     }
//   } catch (error) {
//     console.error('errorMsg:', error);
//   }finally{
//     isLoading.value = false;
//   }
// };


const compNames = ref([] as any[]);
const compInfoListArr = ref([] as any[]);
//예비용
const getMemberInfo = async () => {
  isLoading.value = true;

  try {
    const response = await axios.get("/api/getMemberInfo");
    const responseData = response.data;

    if (Array.isArray(responseData)) {
      // compNames.value = responseData.map(item => item.mb_name); // mb_name만 추출하여 names 배열에 저장
      compNames.value = responseData.map(item => `${item.mb_name}(${item.memid})`);

      compInfoListArr.value = responseData
      return responseData; // 가져온 데이터를 반환
    } else {
      console.error('에러가 발생했습니다.', responseData);
    }
  } catch (error) {
    console.error('errorMsg:', error);
  }finally{
    isLoading.value = false;
  }
};

onMounted(async () => {
  sessionStorage.removeItem('selected_member');
  await getMemReportInfo();
  // await getMemReportInfoBefore();
  await getMemberInfo();

  // const memidArr = memberInfoListArr.value.map(item => item.memid);
  // memberInfoListArrBefore.value = memberInfoListArrBefore.value.filter(item => {
  //   return !memidArr.includes(item.memid);
  // });
})
</script>
<template>
  <v-btn v-if="!loadingStatus"  @click="loadRecentBudget()" class="jogunSelect" style="color: #333;">
  <span v-if="store.state.mainBudNum === '본예산'">전년도예산복사</span>
  <span v-else>최근예산복사</span>
  </v-btn>

  <div v-if="loadingStatus" class="loading-spinner"></div>

</template>
<script setup lang="ts">
import store from '@/store';
import axios from 'axios';
import { ref } from 'vue';
const loadingStatus = ref(false);

const loadRecentBudget = async () => {
  if(loadingStatus.value){
      alert('이미 동작 중입니다.');
      return;
    }
  try{
    loadingStatus.value = true;
    let ment1 = `가장 최근의 예산정보를 불러오겠습니까? \n기존의 데이터는 삭제됩니다.`
    let ment2 = `\n전년도예산복사 진행 시 수가, 사회보험이 올해의 기준에 맞춰집니다.`
    let ment = '';
    if(store.state.mainBudNum === '본예산'){
      ment = ment1+ment2;
    }else{
      ment = ment1;
    }
    const isConfirmed = window.confirm(ment);
    if (isConfirmed) {
      const response = await axios.post(`/api/loadRecentBudget/${store.state.mainComp}/${store.state.mainCompBizGubun}/${store.state.mainYear}/${store.state.mainBudNum}`);
      const result = response.data;
      if (result) {
        alert("예산복사가 완료되었습니다.");
        window.location.reload();
      } else {
        alert("예산복사를 실패하였습니다.");
      }
    } else {
      return;
    }
  }catch (error){
    console.error("errorMsg:", error);
  }finally{
    loadingStatus.value = false;
  }
}

</script>